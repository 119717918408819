
import Modal from "@/components/Modal.vue";
import UsersManageModal from "@/components/users/UsersManageModal.vue";
import municipios from "@/data/municipios";
import dayjs from "@/plugins/day-js";

import SafetyService from "@/services/safety-service";
import store from "@/store";
import User, { UserFlag } from "@/types/user";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import BorrowerService, {
  CreateBorrowerParams
} from "../../services/borrower-service";
import Borrower from "../../types/borrower";
import formatCurrency from "@/utils/formatCurrency";

@Component({
  components: {
    ValidationObserver,
    Modal,
    UsersManageModal,
    ValidationProvider
  },
  directives: { mask }
})
export default class BorrowerManageModal extends Vue {
  @Prop() readonly borrower?: Borrower | undefined;
  formatCurrency = formatCurrency;
  UserFlag = UserFlag;
  service: BorrowerService;
  safetyService: SafetyService;
  loading: boolean = false;
  loadingUsers: boolean = false;
  states = municipios.estados;
  cities: string[] = [];
  editUser: User | null = null;
  users: {
    items: User[];
    total: number;
  } = {
    items: [],
    total: 0
  };

  statusList = [
    { value: 0, text: "Pendente", color: "orange" },
    { value: 1, text: "Ativo", color: "green" },
    { value: 2, text: "Inativo", color: "red" }
  ];

  form: CreateBorrowerParams = {
    id: 0,
    name: "",
    cpf: "",
    homePhone: "",
    userId: this.authenticatedUser?.id,
    address: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
    user: {
      name: "",
      email: "",
      phone: "",
      status: 0,
      flag: 0,
      type: ""
    }
  };

  constructor() {
    super();
    this.service = BorrowerService.getInstance();
    this.safetyService = SafetyService.getInstance();
  }

  mounted(): void {
    this.loadUsers();

    if (this.borrower) {
      const formattedBorrower = {
        ...this.borrower,
        birthDate: dayjs(this.borrower.birthDate).format("DD/MM/YYYY")
      };

      this.form = formattedBorrower;
    }
  }
  async save(): Promise<void> {
    this.loading = true;
    let error, data;

    if (this.borrower && this.borrower.id) {
      const formattedBorrower = {
        ...this.form,
        birthDate: dayjs(this.form.birthDate, "DD/MM/YYYY").toDate()
      };

      [error, data] = await this.service.update(
        this.borrower.id,
        formattedBorrower
      );
    }

    this.loading = false;

    if (error) {
      this.$notify({
        type: "error",
        text: error?.response?.message || "Algo deu errado ao salvar o tomador."
      });
    } else {
      this.$notify({ type: "success", text: "Tomador salvo com sucesso." });
      this.$emit("input", data);
      this.close();
    }
  }
  async loadUsers(): Promise<void> {
    if (this.borrower) {
      this.loadingUsers = true;

      let userIds: number[] = [];

      userIds.push(this.borrower.userId);

      if (userIds.length > 0) {
        const [error, users] = await this.safetyService.listUsers({
          page: 1,
          limit: 100000,
          userIds,
          types: ["BORROWER"]
        });
        if (error) {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(error)
          });
        } else {
          this.users = users;
        }
      }

      this.loadingUsers = false;
    }
  }

  close(): void {
    this.$emit("close");
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  loadCities(): void {
    if (this.form.state) {
      this.cities = municipios.cidadePorEstado(this.form.state);
    } else {
      this.cities = [];
    }
    if (this.form.city && !this.cities.includes(this.form.city)) {
      this.form.city = "";
    }
  }

  get formTitle(): string {
    return this.borrower ? "Editar tomador" : "Cadastrar tomador";
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  async handleActivateUser(): Promise<void> {
    if (this.editUser && this.editUser.id) {
      const [error, updatedUser] = await this.safetyService.activateUser(
        this.editUser.id
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.loadUsers();
        this.editUser = null;
        this.$notify({
          type: "success",
          text: "O usuário foi ativado!"
        });
      }
    }
  }
  async handleInactivateUser(): Promise<void> {
    if (this.editUser && this.editUser.id) {
      const [error, updatedUser] = await this.safetyService.inactivateUser(
        this.editUser.id
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.loadUsers();
        this.editUser = null;
        this.$notify({
          type: "success",
          text: "O usuário foi desativado!"
        });
      }
    }
  }

  get isPartner() {
    return ["PARTNER_MASTER", "PARTNER"].includes(
      this.$store.getters["auth/authenticatedUser"]?.type
    );
  }

  get loggedPartnerId() {
    return this.$store.getters["auth/loggedPartnerId"];
  }

  getFormattedCompanyTime(rawAdmissionDate: string) {
    const admissionDate = rawAdmissionDate;
    const currentDate = dayjs();
    const admissionDateParsed = dayjs(admissionDate, "YYYY-MM-DD", true);
    const companyTime = currentDate.diff(admissionDateParsed, "months");

    const yearsPart = Math.floor(companyTime / 12);
    const monthsPart = companyTime % 12;

    let formatted = "";
    if (yearsPart) {
      formatted += `${yearsPart} `;

      if (yearsPart <= 1) formatted += "ano";
      else formatted += "anos";
    }

    if (yearsPart && monthsPart) formatted += " e ";

    if (monthsPart) {
      formatted += `${monthsPart} `;

      if (monthsPart <= 1) formatted += "mês";
      else formatted += "meses";
    }

    return formatted;
  }
  get hasSaveAuthorization() {
    if (this.hasPermissions(["ATUALIZAR_TOMADOR"])) {
      return true;
    }
    return false;
  }
}
